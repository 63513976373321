<template>
  <div v-loading="loading" class="pdf-preview-wrapper">
    <div class="action-bar flex-row-space-between">
      <el-popover v-model="showSearchBar" placement="bottom" width="340" trigger="manual"
        popper-class="pdf-search-popover">
        <i slot="reference" :class="['icon-search', 'el-icon-search', { 'active': showSearchBar }]"
          @click="toggleShowSearchBar"></i>
        <div class="search-bar flex-row-space-between">
          <el-input ref="searchInput" v-model="keyword" @keyup.native.enter="handleMatchChange(false, canFindNext)"></el-input>
          <div class="icon-wrapper flex-row-space-between">
            <i :class="['el-icon-back', { 'active': canFindPre }]" @click="handleMatchChange(true, canFindPre)"></i>
            <i :class="['el-icon-right', { 'active': canFindNext }]" @click="handleMatchChange(false, canFindNext)"></i>
          </div>
        </div>
      </el-popover>
      <div class="page-num-wrapper">
        <input ref="pageInput" class="page-input" :value="currentPage" @keyup.enter="handlePageChangeByInput"/>
        <span>/ {{ pages }}</span>
      </div>
      <div class="scale-controller flex-row-vertical-center">
        <i class="el-icon-minus" @click="handleScaleChange(false)"></i>
        <i class="el-icon-plus" style="margin-left: 5px" @click="handleScaleChange(true)"></i>
        <!-- <i class="el-icon-download" @click="handleDownload()"></i> -->
      </div>
    </div>
    <div class="pdf-container">
      <pdf-viewer ref="pdfViewer" :src="src" @on-loaded="updateTotalPages" @on-error="onError"
        @on-search="handleSearchResult" @on-page-change="handlePageChangeFromPDFViewer($event, true)"></pdf-viewer>
        <a ref="fileDownLoad" target="_blank" download="1" href="#"></a>
    </div>
  </div>
</template>

<script>
import PdfViewer from "@/components/pdfViewer/PdfViewer";

export default {
  name: "PdfViewerUi",
  components: {
    PdfViewer,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minScale: 50,
      maxScale: 200,
      pages: 0,
      currentPage: 1,
      showSearchBar: false,
      keyword: "",
      currentMatch: 0,
      totalMatch: 0,
      loading: true,
    };
  },
  computed: {
    canPageUp() {
      return this.currentPage < this.pages;
    },
    canPageDown() {
      return this.currentPage > 1;
    },
    canFindPre() {
      return this.currentMatch > 1;
    },
    canFindNext() {
      return this.currentMatch < this.totalMatch;
    },
  },
  watch: {
    keyword(newVal) {
      this.$refs.pdfViewer.cancelSearch();
      if (newVal) this.$refs.pdfViewer.search(newVal);
    },
    showSearchBar(newVal) {
      if (newVal) this.$nextTick(() => this.$refs.searchInput.focus());
      else this.$refs.pdfViewer.cancelSearch();
    },
  },
  mounted() {
    document.onkeydown = (e) => {
      if (e.ctrlKey && e.key === "f") {
        this.showSearchBar = true;
        e.preventDefault();
      } else if (e.key === "Escape") {
        this.showSearchBar = false;
      }
    };
  },
  methods: {
    toggleShowSearchBar() {
      this.showSearchBar = !this.showSearchBar;
    },
    updateTotalPages(pages) {
      this.loading = false;
      this.pages = pages;
    },
    handleDownload() {
        console.log(this.src);
        // let url = window.config.basicServerUrl + "pans/downLoadFile?id=" + id;
        this.$refs.fileDownLoad.href = this.src.split("=")[1];
        this.$refs.fileDownLoad.click();
      },
    onError() {
      this.loading = false;
      this.$message.error("加载失败");
    },
    handleScaleChange(flag) {
      this.$refs.pdfViewer.zoom(flag);
    },
    handlePageChangeFromPDFViewer(page) {
      this.currentPage = page;
    },
    handlePageChangeByInput() {
      const el = this.$refs.pageInput;
      if (isNaN(el.value.trim())) return;
      const page = parseFloat(el.value.trim());
      if (page < 1 || page > this.pages || page % 1 !== 0) return;
      el.blur();
      this.currentPage = page;
      this.$refs.pdfViewer.jumpToPage(this.currentPage);
    },
    handleMatchChange(prev, changeable) {
      if (changeable) {
        this.$refs.pdfViewer.searchAgain(prev);
        this.currentMatch += prev ? -1 : 1;
      }
    },
    handleSearchResult({ current, total }) {
      this.currentMatch = current;
      this.totalMatch = total;
    },
  },
};
</script>

<style scoped lang="scss">
.flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-vertical-center {
  display: flex;
  align-items: center;
}

.pdf-preview-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #525659;
  font-size: 14px;
  color: white;

  .action-bar {
    height: 48px;
    background-color: #323639;
    padding: 0 20px;

    i {
      padding: 8px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background: #424649;
        border-radius: 40px;
      }
    }

    .active {
      color: white;
      cursor: pointer;
    }

    .icon-search {

      &.active {
        background: #424649;
        border-radius: 40px;
      }
    }

    .page-num-wrapper {
      margin: 0 16px;
      color: white;

      .page-input {
        width: 40px;
        padding: 5px 5px;
        text-align: right;
        outline: none;
        color: white;
        box-sizing: border-box;
        font-size: 14px;
        background: none;
        border: none;

        &:hover {
          border-radius: 2px;
          background-color: #191b1c;
          outline: none;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.pdf-search-popover.el-popover {
  padding: 5px 0 5px 5px;
  background-color: #f9f9fa;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.80);
  border: none;

  .search-bar {
    .el-input {
      width: 250px;

      .el-input__inner {
        height: 28px;
      }
    }
  }

  .icon-wrapper {
    margin: 0 10px;

    i {
      font-size: 20px;
      color: #ddd;

      &.active {
        color: black;
        cursor: pointer;
      }
    }
  }
}
</style>
