<template>
  <div>
    <pdf-viewer-ui :src="url"></pdf-viewer-ui>
  </div>
</template>

<script>
  import PdfViewerUi from "@/components/pdfViewer/PdfViewerUi";

  export default {
    name: "PdfReader",
    components: {
      PdfViewerUi,
    },
    data() {
      return {
        url: window.config.SERVER_URL + "pans/downLoadFile?url=" + this.$route.query.url,
      };
    },
  };
</script>
<style lang="scss">
</style>